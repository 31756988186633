import { GetProrationsReturn } from 'graphql/prorations/queries';
import { useDeleteProration } from 'hooks/useDeleteProration';
import { useGetProrations } from 'hooks/useGetProrations';
import { useUpdateEntry } from 'hooks/useUpdateEntry';
import { useUpdateProration } from 'hooks/useUpdateProration';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryCache } from 'react-query';
import { useParams } from 'react-router-dom';
import { OrderProperty } from 'types/order';
import useAlert from 'utils/alert';
import { asyncForEach } from 'utils/helpers';
import { Proration } from 'v2-types/order';

import { ModalData, ProrationsForm } from './types';
import { getPropertyProrationForms } from './utils';

type Params = {
  properties: OrderProperty[]
}

export const useTaxesAndProrations = ({
  properties
}: Params) => {
  const { id: orderId } = useParams<{ id: string }>();
  const queryCache = useQueryCache();
  const [selectedProperty, setSelectedProperty] = useState<OrderProperty>();
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const prorationsForm = useForm<ProrationsForm>({ defaultValues: { forms: [] }, mode: "onChange" });
  const [modalData, setModalData] = useState<ModalData>({ modal: "" });
  const [isUploading, setIsUploading] = useState(false);
  const showAlert = useAlert();

  const { data: prorationsResponse, isLoading: isProrationsLoading } = useGetProrations({
    orderId,
    queryConfig: {
      onSuccess: (response) => {
        if (response.getOrder.prorations && selectedProperty) {
          const forms = getPropertyProrationForms(selectedProperty._id, response.getOrder.prorations);
          prorationsForm.reset({
            forms
          });
        }
      },
      staleTime: 50 * (60 * 1000)
    }
  });

  const selectProperty = (propertyId: string) => {
    const property = properties.find((p) => p._id === propertyId);
    if (property && prorationsResponse) {
      const forms = getPropertyProrationForms(property._id, prorationsResponse.getOrder.prorations);

      prorationsForm.reset({
        forms
      });
    }
    setSelectedProperty(property);
  };

  useEffect(() => {
    if (properties.length > 0) {
      selectProperty(properties[0]._id);
    }
  }, [properties]);

  const updateProrationsCache = (prorations: Proration[]) => {
    queryCache.setQueryData<GetProrationsReturn>(
      [{ orderId }, 'prorations'],
      {
        getOrder: {
          prorations
        }
      },
      { cacheTime: 50 * (60 * 1000) }
    );
    selectProperty(selectedProperty?._id!);
  };

  const [updateProrationMutation] = useUpdateProration({
    queryConfig: {
      onSuccess: (_, { prorationId }) => {
        const proration = prorationsForm.getValues("forms").find(form => form._id === prorationId);
        if (proration) {
          showAlert(`${proration.description} was successfully updated`, 'success');
        }
      }
    }
  });

  const [updateEntry] = useUpdateEntry({});

  const [deleteProration, { isLoading: isDeleteLoading }] = useDeleteProration({
    queryConfig: {
      onSuccess: (response) => {
        setSelectedTabIndex(0);
        updateProrationsCache(response.deleteOrderProration.prorations);
        showAlert(`The proration was successfully deleted`, 'success');
      },
      onError: () => {
        showAlert('Unexpected error ocurred while trying to delete the proration', 'error');
      }
    }
  });

  const onSave = prorationsForm.handleSubmit(async (data) => {
    setIsUploading(true);
    const forms = prorationsForm.formState.dirtyFields.forms ?? [];

    const errors: string[] = [];
    await asyncForEach(forms, async (formFlags, formIndex) => {
      if (formFlags) {
        const formData = data.forms[formIndex!];
        const {
          _id,
          accounting,
          estate,
          days,
          description,
          anualAmount,
          perDiemAmount,
          proratedAmount,
          ...rest } = formData;

        if (formFlags.anualAmount) {
          await updateEntry({
            id: orderId,
            accountingId: formData.anualAmount!._id,
            data: { amount_override: formData.anualAmount!.amount }
          }, {
            onError: () => { errors.push(`Unexpected error while trying to update the amount of ${description}`); }
          });
        }

        if (formFlags.perDiemAmount) {
          await updateEntry({
            id: orderId,
            accountingId: formData.perDiemAmount!._id,
            data: { amount_override: formData.perDiemAmount!.amount ?? 0 }
          }, {
            onError: () => { errors.push(`Unexpected error while trying to update the per diem of ${description}`); }
          });
        }

        if (formFlags.proratedAmount) {
          await updateEntry({
            id: orderId,
            accountingId: formData.proratedAmount!._id,
            data: { amount_override: formData.proratedAmount!.amount }
          }, {
            onError: () => { errors.push(`Unexpected error while trying to update the prorated amount of ${description}`); }
          });
        }

        if (Object.keys(formFlags).filter(key => (key !== "anualAmount"
          && key !== "perDiemAmount" && key !== "proratedAmount")).length > 0) {
          await updateProrationMutation({
            orderId,
            prorationId: formData._id,
            data: {
              ...rest
            }
          }, {
            onError: () => { errors.push(`Unexpected error while trying to update the ${description} proration`); }
          });
        }
      }
    });

    if (errors.length > 0) {
      errors.forEach(error => showAlert(error, 'error', 10000));
    } else {
      queryCache.invalidateQueries([{ orderId }, 'prorations']);
    }
    setIsUploading(false);
  });

  const onDelete = (prorationId: string) => deleteProration({ orderId, prorationId });

  const isLoading = isUploading || isDeleteLoading || isProrationsLoading;
  return {
    selectedProperty,
    setSelectedProperty,
    selectProperty,
    prorationsForm,
    selectedTabIndex,
    setSelectedTabIndex,
    updateProrationsCache,
    modalData,
    setModalData,
    onSave,
    onDelete,
    isLoading
  };
};

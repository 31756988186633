import PageCheckedHome from 'components/organisms/PageCheckedHome';
import PageCheckerAuthenticated from 'components/organisms/PageCheckerAuthenticated';
import PageCheckerIsOnboarded from 'components/organisms/PageCheckerIsOnboarded';
import PageCheckerUnOnboarded from 'components/organisms/PageCheckerUnOnboarded';
import { WorkspaceContextProvider } from 'contexts/workspace-context';
import AccessRequired from 'layouts/access-required-layout/access-required-layout';
import AuthenticatedLayout from 'layouts/authneticated-layout/authenticated-layout';
import OnboardingLayout from 'layouts/onboarding-layout/onboarding-layout';
import OrdersLayout from 'layouts/orders-layout/orders-layout';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ContactEdit from 'views/contacts/contact-edit/contact-edit';
import Contacts from 'views/contacts/contacts/contacts';
import { DocumentRequest } from 'views/document-request/document-request';
import { DocumentsTemplates } from 'views/documents-templates/documents-templates';
import Orders from 'views/index/orders';
import NotFound from 'views/not-found/not-found';
import { Notes } from 'views/notes';
import OnboardingTermsAndConditions from 'views/onboarding/onboarding-terms-and-conditions/onboarding-terms-and-conditions';
import OnboardingWelcome from 'views/onboarding/onboarding-welcome/onboarding-welcome';
import Profile from 'views/profile/profile';
import SignIn from 'views/sign-in/sign-in';
import TermsOfService from 'views/terms-of-service/terms-of-service';
import Unsuscribe from 'views/unsuscribe/unsuscribe';

import ResetPassword from '../views/reset-password/reset-password';

const RootRoutes = () => (
  <>
    <Switch>

      <Route
        exact
        path="/contacts"
        render={() => (
          <PageCheckerAuthenticated
            render={() => (
              <PageCheckerUnOnboarded
                render={() => (
                  <AuthenticatedLayout>
                    <AccessRequired>
                      <Contacts />
                    </AccessRequired>
                  </AuthenticatedLayout>
                )}
              />
            )}
          />
        )}
      />

      <Route
        exact
        path="/document-templates"
        render={() => (
          <PageCheckerAuthenticated
            render={() => (
              <PageCheckerUnOnboarded
                render={() => (
                  <AuthenticatedLayout>
                    <AccessRequired>
                      <DocumentsTemplates />
                    </AccessRequired>
                  </AuthenticatedLayout>
                )}
              />
            )}
          />
        )}
      />

      <Route
        exact
        path="/contacts/:id"
        render={() => (
          <PageCheckerAuthenticated
            render={() => (
              <PageCheckerUnOnboarded
                render={() => (
                  <AuthenticatedLayout>
                    <AccessRequired>
                      <ContactEdit />
                    </AccessRequired>
                  </AuthenticatedLayout>
                )}
              />
            )}
          />
        )}
      />

      <Route
        exact
        path="/orders"
        render={() => (
          <PageCheckerAuthenticated
            render={() => (
              <PageCheckerUnOnboarded
                render={() => (
                  <AuthenticatedLayout>
                    <Orders />
                  </AuthenticatedLayout>
                )}
              />
            )}
          />
        )}
      />

      <Route
        path="/orders/details/:id?"
        render={() => (
          <PageCheckerAuthenticated
            render={() => (
              <PageCheckerUnOnboarded
                render={() => (
                  <AuthenticatedLayout>
                    <WorkspaceContextProvider>
                      <OrdersLayout />
                    </WorkspaceContextProvider>
                  </AuthenticatedLayout>
                )}
              />
            )}
          />
        )}
      />

      <Route
        path="/profile"
        render={() => (
          <PageCheckerAuthenticated
            render={() => (
              <PageCheckerUnOnboarded
                render={() => (
                  <AuthenticatedLayout>
                    <Profile />
                  </AuthenticatedLayout>
                )}
              />
            )}
          />
        )}
      />

      <Route
        path="/terms-of-service"
        render={() => (
          <PageCheckerAuthenticated
            render={() => (
              <PageCheckerUnOnboarded
                render={() => (
                  <AuthenticatedLayout>
                    <TermsOfService />
                  </AuthenticatedLayout>
                )}
              />
            )}
          />
        )}
      />

      <Route
        exact
        path="/onboarding/terms-and-conditions"
        render={() => (
          <PageCheckerAuthenticated
            render={() => (
              <PageCheckerIsOnboarded
                render={() => (
                  <OnboardingLayout>
                    <OnboardingTermsAndConditions />
                  </OnboardingLayout>
                )}
              />
            )}
          />
        )}
      />

      <Route
        exact
        path="/onboarding/welcome"
        render={() => (
          <PageCheckerAuthenticated
            render={() => (
              <PageCheckerIsOnboarded
                render={() => (
                  <OnboardingLayout>
                    <OnboardingWelcome />
                  </OnboardingLayout>
                )}
              />
            )}
          />
        )}
      />

      <Route exact path="/" render={() => <PageCheckedHome />} />

      <Route
        exact
        path="/document_request/:token?"
        render={() => <DocumentRequest />}
      />

      <Route
        exact
        path="/notes"
        render={() => (
          <PageCheckerAuthenticated
            render={() => (
              <PageCheckerUnOnboarded
                render={() => (
                  <AuthenticatedLayout>
                    <AccessRequired>
                      <Notes />
                    </AccessRequired>
                  </AuthenticatedLayout>
                )}
              />
            )}
          />
        )}
      />

      <Route exact path="/unsubscribe/:token?" render={() => <Unsuscribe />} />

      <Route exact path="/sign-in" render={() => <SignIn />} />

      <Route exact path="/reset-password" render={() => <ResetPassword />} />

      <Route path="*" render={() => <NotFound />} />
    </Switch>
  </>
);

export default RootRoutes;

import { Grid, Paper, makeStyles } from '@material-ui/core';
import React from 'react';
import Header from '../components/header';
import { useTranslation } from 'react-i18next';
import { general } from '../styles/general';
import TableCharges from '../components/tableCharges';
import useGetCharges from '../services/getCharges';
import SkeletonCharges from '../components/skeleton';

const useStyles = makeStyles((theme) => general(theme));

const ChargesB = () => {
  const { t } = useTranslation();
  const classesA = useStyles();
  const currentLetter = 'B';

  const { accounting, updatedAccounting, isFetching, setHideTax, hideTax, redirectToPolicyInfo } =
    useGetCharges(currentLetter);

  return (
    <Grid container className={classesA.chargesContainer} direction="column" spacing={2}>
      <Grid item xs={12} style={{ marginBottom: 24 }}>
        <Paper>
          <Header
            setHideTax={setHideTax}
            hideTax={hideTax}
            title={t('charges:origination-charges')}
            chargesIdentifier={currentLetter}
            t={t}
            redirectToPolicyInfo={redirectToPolicyInfo}
          />
          {isFetching ? (
            <SkeletonCharges />
          ) : (
            <TableCharges
              accountingData={accounting}
              currentLetter={currentLetter}
              updatedAccounting={updatedAccounting}
              hideTax={hideTax}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ChargesB;

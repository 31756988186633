import gqlRequest from 'graphql/generic';

type OrderCharges = {
  accounting_id: string;
  description: string;
  payee_id: string;
  tax: number;
  per_month: number;
  months: number;
  buyer_at: number;
  buyer_before: number;
  seller_at: number;
  seller_before: number;
  by_others: number;
  number: number;
};

type UpdateChargesParams = {
  id: string;
  data: OrderCharges[];
};

type MutationChargesResponse = {
  _id: string;
};

const mutationUpdateOrder = `
  mutation HelpOrderCharges($id: ID!, $data: [OrderChargesContent!]!, $filters: OrderFilters) {
    helpOrderCharges(_id: $id, data: $data, filters: $filters) {
      _id
    }
  }`;

export const updateCharges = (payload: UpdateChargesParams) =>
  gqlRequest<MutationChargesResponse, UpdateChargesParams>(mutationUpdateOrder, payload);

type DeleteChargesParams = {
  id: string;
  accountingId: string;
};

const mutationDeleteOrder = `
  mutation DeleteOrderLedgerEntry($id: ID!, $accountingId: ID!) {
    deleteOrderLedgerEntry(_id: $id, accounting_id: $accountingId) {
      _id
    }
  }`;

export const deleteCharges = (payload: DeleteChargesParams) =>
  gqlRequest<MutationChargesResponse, DeleteChargesParams>(mutationDeleteOrder, payload);

/* eslint-disable max-len */
import { MessageContentImageUrl } from "@langchain/core/messages";
import { ColumnsType } from "views/orders/accounting/services";
import CellEditable from "../components/cell-editable";
import React from 'react';
import PayeeSelection from "../components/payee-selection";

export const generatePDFRequest = (base64Images: string[]): MessageContentImageUrl[] =>
  base64Images.map((image) => ({
    type: 'image_url',
    image_url: {
      url: image
    }
  }
  ));

export const assembleChains = (base64Images: string[]) => {
  return base64Images.map((image) => ([{
    type: 'image_url',
    image_url: {
      url: image
    }
  },
  {
    type: "text",
    text: `
                 You are an AI that only responds with JSON if there is no data to fill the JSON return a void JSON.
                 ONLY if there is a explicit table and if the table have any of these titles: 'Loan Costs','Other Costs'. 
                 If you find a table with one of these titles give me an output with ONLY the charges from the section that have 
                 one this titles: 'A. Origination Charges','B. Services Borrower Did Not Shop For', 'C. Services Borrower Did Shop For','E. Taxes and Other Government Fees',
                 'F. Prepaids','G. Inial Escrow Payment at Closing', 'H. Other'.
                  Give a JSON format group by the letter with the next format:
                    * number: number specified in the charge, is it is not specified put 1
                    * d: description of the charge, if it is not specified put null
                    * l: letter in which this charge is located
                    * b_ac: amount specified to pay in the at closing column paid by borrower, if there is not specified or it's null remove the property
                    * b_bc: amount specified to pay in the before closing column paid by borrower,  if there is not specified or it's null remove the property
                    * s_ac: amount specified to pay in the at closing column paid by seller,  if there is not specified or it's null remove the property
                    * s_bc: amount specified to pay in the before closing column paid by seller,  if there is not specified or it's null remove the property
                    * b_o: amount specified to pay in the by others column, if there is not specified or it's null remove the property
                    * p: when the charge description has a 'to' word, who belongs this charge, put only the name, remove the word 'to'
                  remove all entries with null description from the JSON and remove all properties with null values from the final JSON, if there is not a table in the page, give me a empty JSON
                
    `
  }
  ]));
};

type partAmounts = {
  at_closing: number,
  before_closing: number
}

type chargefield = {
  letter: string,
  number: number,
  description: string,
  buyer: partAmounts
  seller: partAmounts
  by_others: number,
  payee: {
    name: string,
    id: string
  },
  payeeText?: string,
}

export type formType = {
  charges: chargefield[]
}

export const processResponse = (param: string) => {
  const firstIndex = param.indexOf('{');
  const slicedfromIndex = param.substring(firstIndex);
  const objectJSON = JSON.parse(slicedfromIndex.replace(/[\r\n]+/gm, ' '));
  const flattedmap = Object.keys(objectJSON).map((key) => ([...objectJSON[key]]));
  return flattedmap.flat();
};

export const chargesAIColumns = (
  methods,
  t,
  theme,
  onDelete
): ColumnsType => ({
  number: {
    header: { title: 'No.', width: '50px' },
    renderer: ({ row }) => (
      <CellEditable
        control={methods.control}
        name={`charges.${row.index}.number`}
        required
      />
    ),
  },
  description: {
    header: {
      title: t('charges:description'),
      align: 'left',
      width: '150px',
      textColor: theme.palette.primary.light,
    },
    renderer: ({ row }) => (
      <CellEditable
        control={methods.control}
        name={`charges.${row.index}.description`}
        required
        text
      />
    ),
  },
  payee: {
    header: {
      title: t('charges:payee'),
      align: 'left',
      width: '120px',
      textColor: theme.palette.primary.light,
    },
    renderer: ({ row }) => (
      <PayeeSelection
        methods={methods}
        index={row.index}
      />
    ),
  },
  paid_borrower: {
    header: {
      title: t('charges:paid-by-borrower'),
      textColor: theme.palette.primary.light,
    },
    columns: [
      {
        header: {
          title: t('charges:at-closing'),
          width: '100px',
          textColor: theme.palette.primary.light,
          align: 'left'
        },
        renderer: ({ row }) => (
          <CellEditable control={methods.control} name={`charges.${row.index}.buyer.at_closing`} />
        ),
      },
      {
        header: {
          title: t('charges:before-closing'),
          width: '100px',
          align: 'left',
          textColor: theme.palette.primary.light,
        },
        renderer: ({ row }) => (
          <CellEditable control={methods.control} name={`charges.${row.index}.buyer.before_closing`} />

        ),
      },
    ],
  },
  paid_seller: {
    header: {
      title: t('charges:paid-by-seller'),
      textColor: theme.palette.primary.light,
    },
    columns: [
      {
        header: {
          title: t('charges:at-closing'),
          width: '100px',
          align: 'left',
          textColor: theme.palette.primary.light,
        },
        renderer: ({ row }) => (
          <CellEditable control={methods.control} name={`charges.${row.index}.seller.at_closing`} />
        ),
      },
      {
        header: {
          title: t('charges:before-closing'),
          width: '100px',
          align: 'left',
          textColor: theme.palette.primary.light,
        },
        renderer: ({ row }) => (
          <CellEditable control={methods.control} name={`charges.${row.index}.seller.before_closing`} />
        ),
      },
    ],
  },
  by_others: {
    header: {
      title: t('charges:by-others'),
      width: '100px',
      align: 'left',
      textColor: theme.palette.primary.light,
    },
    renderer: ({ row }) => (
      <CellEditable
        control={methods.control}
        name={`charges.${row.index}.by_others`}
        onDelete={() => { onDelete(row.index); }}
        showDelete
      />

    ),
  },
});

export const stateData = (state, action) => {
  switch (action.type) {
    case 'loading-data':
      return { mode: 'loading-data', };
    case 'error':
      return {
        mode: 'error',
        data: action.data
      };
    case 'fetch':
      return {
        mode: 'fetch',
        data: null
      };
    case 'fetched':
      return {
        mode: 'fetched',
        data: null
      };
    default:
      return {
        mode: null,
        data: null
      };
  }
};

export const intialReducerState = {
  mode: 'fetch',
  data: null
};

import * as Yup from 'yup';

export const validationSchema = Yup.object({
  code: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  kind: Yup.string().required('Required'),
  number: Yup.string().required('Required'),
});

export const validationSchemaCharges = Yup.object().shape({
  charges: Yup.array().of(
    Yup.object().shape({
      by_others: Yup.number()
        .typeError('El campo by_others debe ser un número')
        .required('El campo by_others es requerido'),
      buyer_at: Yup.number()
        .typeError('El campo buyer_at debe ser un número')
        .required('El campo buyer_at es requerido'),
      buyer_before: Yup.number()
        .typeError('El campo buyer_before debe ser un número')
        .required('El campo buyer_before es requerido'),
      seller_at: Yup.number()
        .typeError('El campo seller_at debe ser un número')
        .required('El campo seller_at es requerido'),
      seller_before: Yup.number()
        .typeError('El campo seller_before debe ser un número')
        .required('El campo seller_before es requerido'),
      tax: Yup.number()
        .typeError('El campo tax debe ser un número')
        .required('El campo tax es requerido'),
      per_month: Yup.number()
        .typeError('El campo tax debe ser un número')
        .required('El campo tax es requerido'),
      months: Yup.number()
        .typeError('El campo tax debe ser un número')
        .required('El campo tax es requerido'),
    })
  ),
});

import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { formType } from '../services';
import { Box, IconButton, InputAdornment, makeStyles } from '@material-ui/core';
import TextField from 'components/text-field';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NumberField from 'components/number-field';

const useStyles = makeStyles((theme) => ({
  errorInput: {
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.error.main,
    },
  }
}));

type cellEditableProps = {
  control: Control<formType, any>
  name: any,
  label?: string,
  rows?: number,
  required?: boolean,
  showDelete?: boolean,
  onDelete?: () => void,
  text?: boolean
}

const CellEditable: FC<cellEditableProps> = ({
  control,
  name,
  label,
  rows,
  required,
  showDelete,
  onDelete,
  text
}) => {
  const classes = useStyles();
  return (
    <Box
      style={{ width: '100%', }}
    >
      <Controller
        control={control}
        name={name}
        rules={{ required }}
        render={({ field: { ref, ...field } }) => (
          text ?
            <TextField
              {...field}
              fullWidth
              label={label}
              variant="standard"
              value={field.value}
              InputProps={{
                disableUnderline: true,
                size: 'small',
                margin: 'dense',
                endAdornment: showDelete && (
                  <InputAdornment position="end">
                    <IconButton
                      style={{ padding: 0 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (onDelete) {
                          onDelete();
                        }
                      }}
                    >
                      <FontAwesomeIcon icon="trash-alt" style={{ fontSize: '14px' }} />
                    </IconButton>
                  </InputAdornment>),
                style: {
                  fontSize: '14px',
                  color: 'black',
                }
              }}
              multiline
              customClassName={clsx({ [classes.errorInput]: field.value === '' && required })}
              rows={rows || 0}
            /> :
            <NumberField
              {...field}
              naked
              InputProps={{
                disableUnderline: true,
                size: 'small',
                margin: 'dense',
                endAdornment: showDelete && (
                  <InputAdornment position="end">
                    <IconButton
                      style={{ padding: 0 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (onDelete) {
                          onDelete();
                        }
                      }}
                    >
                      <FontAwesomeIcon icon="trash-alt" style={{ fontSize: '14px' }} />
                    </IconButton>
                  </InputAdornment>),
                style: {
                  fontSize: '14px',
                  color: 'black',
                }
              }}
            />
        )}
      />
    </Box>
  );
};

export default CellEditable;

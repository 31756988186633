import React, { FC, useCallback, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { formType, chargesAIColumns } from '../services';
import { useTranslation } from 'react-i18next';
import TableComponent from 'components/Table';
import {
  Grid,
  IconButton,
  Typography,
  useTheme
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BaseButton } from 'components/BaseButton';
import { chargesHelperAI } from '../services/mutations';
import { useMutation, useQueryCache } from 'react-query';
import { useParams } from 'react-router-dom';

type formchargesProps = {
  charges: any[],
  onClose: () => void,
}

const FormCharges: FC<formchargesProps> = ({
  charges,
  onClose
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const queryCache = useQueryCache();
  const { id: orderId } = useParams<{ id: string }>();
  const methods = useForm<formType>({
    defaultValues: {
      charges: charges.map((charge) => ({
        number: charge?.number || 0,
        description: charge?.description || '',
        letter: charge?.letter || '',
        payeeText: charge?.payee || '',
        payee: {
          name: '',
          id: ''
        },
        by_others: charge?.by_others || 0,
        seller: {
          at_closing: charge?.seller_at_closing || 0,
          before_closing: charge?.seller_before_closing || 0
        },
        buyer: {
          at_closing: charge?.borrower_at_closing || 0,
          before_closing: charge?.borrower_before_closing || 0
        },
      }))
    }
  });
  const { control, handleSubmit, formState: { errors } } = methods;
  const [helpChargesAI, helpChargesAIResponse] = useMutation(chargesHelperAI, {
    onSuccess: (data) => {
      helpChargesAIResponse.reset();
      queryCache.setQueryData(['accounting-order', orderId], { getOrder: { accounting: data?.helpOrderChargesByAI?.accounting || [] } });
      onClose();
    }
  });

  const { id } = useParams<{ id: string }>();

  const { fields, append, remove } = useFieldArray({ control, name: 'charges' });
  const formattedFields = fields.map((field, index) => ({ ...field, index }));
  const letters = fields.map((item) => ({ letter: item.letter })).reduce((accumulator: any[], item) => {
    if (!accumulator.find(i => i.letter === item.letter) && item.letter !== '') {
      accumulator.push(item);
    }
    return accumulator;
  }, []);

  const columns = chargesAIColumns(methods, t, theme, remove);

  const handleCreateOrderAI = useCallback((data: formType) => {
    helpChargesAI({
      id,
      amounts: data.charges.filter(el => el.letter !== 'I').map((element) => ({
        letter: element.letter,
        number: element.number.toString(),
        description: element.description,
        buyer: {
          at_closing: parseFloat(element.buyer.at_closing.toString()) || 0,
          before_closing: parseFloat(element.buyer.before_closing.toString()) || 0
        },
        seller: {
          at_closing: parseFloat(element.seller.at_closing.toString()) || 0,
          before_closing: parseFloat(element.seller.before_closing.toString()) || 0
        },
        others: parseFloat(element.by_others.toString()) || 0,
        payee: element.payee.id !== '' ? element.payee.id : null
      }))
    });
  }, [helpChargesAI, id]);

  return (
    <form onSubmit={handleSubmit((data) => handleCreateOrderAI(data))}>
      <Grid container direction="column" spacing={4}>

        <Grid item xs={12}>
          <Grid container direction="row">
            <Grid item xs={11} style={{ textAlign: 'center' }}>
              <Typography variant="h6" style={{ color: 'black', marginBottom: 16 }}>
                {t('charges:charges-collected')}
              </Typography>
              <Typography variant="body2" style={{ color: 'black' }}>
                {t('charges:verify-charges')}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                style={{ padding: 0 }}
                onClick={(e) => {
                  e.stopPropagation();
                  onClose();
                }}
              >
                <FontAwesomeIcon icon="times" size="xs" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        {letters.map((item) => (
          <Grid item xs={12} key={item.letter}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="body1" style={{ fontWeight: 500, color: 'black' }}>{`Charges ${item.letter}`}</Typography>
              </Grid>
              <Grid item>
                <TableComponent
                  dataSet={formattedFields.filter(f => f.letter === item.letter)}
                  columns={columns}
                  disabledBorderLeft
                />
              </Grid>
              <Grid item>
                <BaseButton
                  buttonVariant="outlined"
                  text={`Add charge in ${item.letter}`}
                  icon="plus"
                  textColor="#FB8C00"
                  iconColor="#FB8C00"
                  onClick={(e) => {
                    e.stopPropagation();
                    append({
                      letter: item.letter,
                      number: 0,
                      description: 'Put the charge Description Here',
                      buyer: {
                        at_closing: 0,
                        before_closing: 0
                      },
                      seller: {
                        at_closing: 0,
                        before_closing: 0
                      },
                      by_others: 0,
                      payee: {
                        id: '',
                        name: ''
                      },
                      payeeText: ''
                    });
                  }}
                />
              </Grid>
            </Grid>

          </Grid>
        ))}
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent="flex-end" spacing={2}>
            <Grid item>
              <BaseButton
                text="Continue"
                type="submit"
                isLoading={helpChargesAIResponse.isLoading}
              />
            </Grid>
            <Grid item>
              <BaseButton
                buttonVariant="outlined"
                buttonColor="primary"
                text="Cancel"
                onClick={(e) => {
                  e.stopPropagation();
                  onClose();
                }}
                disabled={helpChargesAIResponse.isLoading}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default FormCharges;
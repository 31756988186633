import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import AddLedgerEntry from 'components/add-entry-modal/add-entry-modal';
import useTableCharges from '../hooks/tableCharges-hooks';
import { AccountingType } from 'v2-types/order';
import { SearchPartyInput } from 'components/search-party-input';
import NumberField from 'components/number-field';
import TextField from 'components/text-field';
import { Controller, useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuRow from './menuRow';
import ConfirmationModal from './confirmationModal';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchemaCharges } from '../services/validate-entry';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    padding: `${theme.spacing(2)}px`,
  },
  buttonContainer: {
    padding: `${theme.spacing(2)}px`,
  },
  tableContainer: {
    maxHeight: '460px',
  },
  table: {
    padding: `${theme.spacing(2)}px`,
  },
  tableHeadRowFirst: {
    position: 'sticky',
    background: 'white',
    zIndex: theme.zIndex.drawer + 1,
    top: 0,
  },
  tableHeadRowSecond: {
    position: 'sticky',
    background: 'white',
    zIndex: theme.zIndex.drawer + 1,
    top: 56,
  },
  searchContact: {
    '& .MuiInputLabel-filled': {
      color: theme.palette.primary.dark,
    },
    '& .MuiFilledInput-root': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.dark,
      border: 'unset',
      borderRadius: '4px',

      '&.MuiFilledInput-underline::before': {
        content: 'unset',
      },

      '&.MuiFilledInput-underline::after': {
        content: 'unset',
      },
    },
  },
  numberField: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.dark,

      '& .MuiOutlinedInput-notchedOutline': {
        border: 'unset',
      },
    },
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.dark,

      '& .MuiOutlinedInput-notchedOutline': {
        border: 'unset',
      },
    },
  },
  tableHeaderDescriptionPayee: {
    minWidth: '300px',
  },
  tableHeaderNumber: {
    minWidth: '110px',
  },
  numberInput: {
    '& .MuiOutlinedInput-root': {
      width: '35px',
      height: '35px',
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '5px',
      color: theme.palette.primary.dark,

      '& .MuiOutlinedInput-notchedOutline': {
        border: 'unset',
      },

      '& .MuiOutlinedInput-input ': {
        padding: `${theme.spacing(0.25)}px`,
        textAlign: 'center',
      },
    },
  },
  numberContainer: {
    width: '35px',
    height: '35px',
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    color: theme.palette.primary.dark,
  },
  tableChargeRow: {
    padding: `${theme.spacing(1)}px`,
  },
  addEntry: {
    textTransform: 'capitalize',
  },
  updateCharges: {
    textTransform: 'capitalize',
  },
  tableMenuRow: {
    padding: `${theme.spacing(1)}px`,
    textAlign: 'center',
    cursor: 'pointer',
  },
}));

const TableCharges = ({ accountingData, currentLetter, updatedAccounting, hideTax }) => {
  const classes = useStyles();
  const {
    openModal,
    setOpenModal,
    resolver,
    hanldeUpdateCharge,
    isLoading,
    t,
    anchorElRow,
    handleClickMenu,
    handleCloseMenu,
    openMenu,
    openDialog,
    handleCloseDialog,
    handleOpenDialog,
    handleDeleteCharge,
    formatedAccountingData,
  } = useTableCharges(accountingData);

  const handleClick = () => {
    setOpenModal(true);
  };

  const hideFields = {
    months: true,
    amount_override: true,
    months_advanced: true,
    annuality: true,
    kind: true,
    code: true,
    entryDate: true,
  };

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { isValid, errors },
  } = useForm({
    defaultValues: {
      charges: formatedAccountingData,
    },
    resolver: yupResolver(validationSchemaCharges),
    mode: 'onChange',
  });

  const onSubmit = (data) => {
    const arrayChargesToUpload = data.charges.map((charge) => {
      const newCharge = charge;
      delete newCharge.kind;
      delete newCharge.payee;
      return newCharge;
    });

    hanldeUpdateCharge(arrayChargesToUpload);
  };

  useEffect(() => {
    reset({
      charges: formatedAccountingData,
    });
  }, [formatedAccountingData]);

  return (
    <Box className={classes.container}>
      <TableContainer className={classes.tableContainer}>
        <form>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableHeadRowFirst}>
                <TableCell align="center" colSpan={currentLetter === 'G' ? 4 : 6} />
                <TableCell align="center" colSpan={2}>
                  {t('charges:paid-by-borrower')}
                </TableCell>
                <TableCell align="center" colSpan={2}>
                  {t('charges:paid-by-seller')}
                </TableCell>
                <TableCell />
              </TableRow>
              <TableRow className={classes.tableHeadRowSecond}>
                <TableCell />
                <TableCell />
                <TableCell className={classes.tableHeaderDescriptionPayee}>
                  {t('charges:description')}
                </TableCell>
                <TableCell className={classes.tableHeaderDescriptionPayee}>
                  {t('charges:payee')}
                </TableCell>
                {currentLetter === 'G' && (
                  <>
                    <TableCell className={classes.tableHeaderNumber}>
                      {t('charges:per-month')}
                    </TableCell>
                    <TableCell className={classes.tableHeaderNumber}>
                      {t('charges:number-of-month')}
                    </TableCell>
                  </>
                )}
                {!hideTax && (
                  <TableCell className={classes.tableHeaderNumber}>{t('charges:tax')}</TableCell>
                )}
                <TableCell className={classes.tableHeaderNumber}>
                  {t('charges:at-closing')}
                </TableCell>
                <TableCell className={classes.tableHeaderNumber}>
                  {t('charges:before-closing')}
                </TableCell>
                <TableCell className={classes.tableHeaderNumber}>
                  {t('charges:at-closing')}
                </TableCell>
                <TableCell className={classes.tableHeaderNumber}>
                  {t('charges:before-closing')}
                </TableCell>
                <TableCell className={classes.tableHeaderNumber}>
                  {t('charges:by-others')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formatedAccountingData.map((charge, index) => (
                <TableRow key={charge.accounting_id}>
                  {charge.kind === 'manual' ? (
                    <TableCell
                      className={classes.tableMenuRow}
                      onClick={(e) => handleClickMenu(e, charge.accounting_id)}
                    >
                      <FontAwesomeIcon icon="ellipsis-v" />
                    </TableCell>
                  ) : (
                    <TableCell className={classes.tableChargeRow} />
                  )}
                  {charge.kind === 'manual' ? (
                    <TableCell className={classes.tableChargeRow}>
                      <Controller
                        name={`charges.${index}.number`}
                        control={control}
                        render={({ field: { ref, onChange, ...field } }) => (
                          <NumberField
                            {...field}
                            color="secondary"
                            className={classes.numberInput}
                            variant="outlined"
                            onValueChange={(prop) => {
                              onChange(prop.floatValue);
                            }}
                            value={getValues(`charges.${index}.number`)}
                          />
                        )}
                      />
                    </TableCell>
                  ) : (
                    <TableCell className={classes.tableChargeRow}>
                      <Box className={classes.numberContainer}>{charge.number}</Box>
                    </TableCell>
                  )}

                  {charge.kind === 'manual' ? (
                    <TableCell className={classes.tableChargeRow}>
                      <Controller
                        name={`charges.${index}.description`}
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <TextField
                            {...field}
                            inputRef={ref}
                            customClassName={classes.textField}
                            color="secondary"
                            variant="outlined"
                            value={getValues(`charges.${index}.description`)}
                          />
                        )}
                      />
                    </TableCell>
                  ) : (
                    <TableCell className={classes.tableChargeRow}>{charge.description}</TableCell>
                  )}

                  <TableCell className={classes.tableChargeRow}>
                    <Controller
                      name={`charges.${index}.payee_id`}
                      control={control}
                      render={({ field: { onChange, ...field } }) => (
                        <SearchPartyInput
                          {...field}
                          initialValue={getValues(`charges.${index}.payee`)}
                          className={classes.searchContact}
                          onSelectParty={(party) => {
                            onChange(party?._id);
                          }}
                        />
                      )}
                    />
                  </TableCell>

                  {currentLetter === 'G' && (
                    <>
                      <TableCell className={classes.tableChargeRow}>
                        <Controller
                          name={`charges.${index}.per_month`}
                          control={control}
                          render={({ field: { ref, onChange, ...field } }) => (
                            <NumberField
                              {...field}
                              color="secondary"
                              className={classes.numberField}
                              variant="outlined"
                              onValueChange={(prop) => {
                                onChange(prop.floatValue);
                              }}
                              value={getValues(`charges.${index}.per_month`)}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell className={classes.tableChargeRow}>
                        <Controller
                          name={`charges.${index}.months`}
                          control={control}
                          render={({ field: { ref, onChange, ...field } }) => (
                            <NumberField
                              {...field}
                              color="secondary"
                              className={classes.numberField}
                              variant="outlined"
                              onValueChange={(prop) => {
                                onChange(prop.floatValue);
                              }}
                              value={getValues(`charges.${index}.months`)}
                            />
                          )}
                        />
                      </TableCell>
                    </>
                  )}

                  {!hideTax && (
                    <TableCell className={classes.tableChargeRow}>
                      <Controller
                        name={`charges.${index}.tax`}
                        control={control}
                        render={({ field: { onChange, ...field } }) => (
                          <NumberField
                            {...field}
                            color="secondary"
                            className={classes.numberField}
                            onValueChange={(prop) => {
                              onChange(prop.floatValue);
                            }}
                            prefix="$"
                            thousandSeparator
                            variant="outlined"
                            value={getValues(`charges.${index}.tax`)}
                          />
                        )}
                      />
                    </TableCell>
                  )}
                  <TableCell className={classes.tableChargeRow}>
                    <Controller
                      name={`charges.${index}.buyer_at`}
                      control={control}
                      render={({ field: { onChange, ...field } }) => (
                        <NumberField
                          {...field}
                          color="secondary"
                          className={classes.numberField}
                          onValueChange={(prop) => {
                            onChange(prop.floatValue);
                          }}
                          prefix="$"
                          thousandSeparator
                          variant="outlined"
                          value={getValues(`charges.${index}.buyer_at`)}
                        />
                      )}
                    />
                  </TableCell>

                  <TableCell className={classes.tableChargeRow}>
                    <Controller
                      name={`charges.${index}.buyer_before`}
                      control={control}
                      render={({ field: { onChange, ...field } }) => (
                        <NumberField
                          {...field}
                          color="secondary"
                          className={classes.numberField}
                          onValueChange={(prop) => {
                            onChange(prop.floatValue);
                          }}
                          prefix="$"
                          thousandSeparator
                          variant="outlined"
                          value={getValues(`charges.${index}.buyer_before`)}
                        />
                      )}
                    />
                  </TableCell>

                  <TableCell className={classes.tableChargeRow}>
                    <Controller
                      name={`charges.${index}.seller_at`}
                      control={control}
                      render={({ field: { onChange, ...field } }) => (
                        <NumberField
                          {...field}
                          color="secondary"
                          className={classes.numberField}
                          onValueChange={(prop) => {
                            onChange(prop.floatValue);
                          }}
                          prefix="$"
                          thousandSeparator
                          variant="outlined"
                          value={getValues(`charges.${index}.seller_at`)}
                        />
                      )}
                    />
                  </TableCell>

                  <TableCell className={classes.tableChargeRow}>
                    <Controller
                      name={`charges.${index}.seller_before`}
                      control={control}
                      render={({ field: { onChange, ...field } }) => (
                        <NumberField
                          {...field}
                          color="secondary"
                          className={classes.numberField}
                          onValueChange={(prop) => {
                            onChange(prop.floatValue);
                          }}
                          prefix="$"
                          thousandSeparator
                          variant="outlined"
                          value={getValues(`charges.${index}.seller_before`)}
                        />
                      )}
                    />
                  </TableCell>

                  <TableCell className={classes.tableChargeRow}>
                    <Controller
                      name={`charges.${index}.by_others`}
                      control={control}
                      render={({ field: { onChange, ...field } }) => (
                        <NumberField
                          {...field}
                          color="secondary"
                          className={classes.numberField}
                          onValueChange={(prop) => {
                            onChange(prop.floatValue);
                          }}
                          prefix="$"
                          thousandSeparator
                          variant="outlined"
                          value={getValues(`charges.${index}.by_others`)}
                        />
                      )}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </form>
      </TableContainer>
      <Box
        className={classes.buttonContainer}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Button
          className={classes.addEntry}
          variant="outlined"
          color="secondary"
          onClick={handleClick}
        >
          {t('charges:add-entry')}
        </Button>

        <Button
          className={classes.updateCharges}
          disabled={!isValid}
          color="secondary"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          {t('charges:update-charges')}
        </Button>
      </Box>
      <AddLedgerEntry
        open={openModal}
        handleClose={() => setOpenModal(false)}
        onSuccesAddEntry={updatedAccounting}
        accountingFilter={currentLetter}
        prevData={{ letter: currentLetter, kind: 'manual', code: 'charge' } as AccountingType}
        kindAllowedEntry="manual"
        codeAllowedEntry="charge"
        resolver={resolver}
        hideFields={hideFields}
        disableFields={{ letter: true }}
      />

      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="secondary" />
      </Backdrop>

      <MenuRow
        handleOpenDialog={handleOpenDialog}
        anchorElRow={anchorElRow}
        openMenu={openMenu}
        handleCloseMenu={handleCloseMenu}
      />

      <ConfirmationModal
        anchorElRow={anchorElRow}
        handleDeleteCharge={handleDeleteCharge}
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
      />
    </Box>
  );
};

export default TableCharges;

import { Box, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `${theme.spacing(2)}px`,
  },
  containerItems: {
    display: 'flex',
    gap: '10px',
    marginBottom: '10px',
  },
}));

const SkeletonCharges = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.containerItems}>
        <Skeleton variant="text" width={300} height={40} />
        <Skeleton variant="text" width={300} height={40} />
        <Skeleton variant="text" width={150} height={40} />
        <Skeleton variant="text" width={150} height={40} />
        <Skeleton variant="text" width={150} height={40} />
        <Skeleton variant="text" width={150} height={40} />
      </Box>
      {Array.from({ length: 6 }).map((_, index) => (
        <Box key={`skeItem-${_}`} className={classes.containerItems}>
          <Skeleton variant="rect" width={300} height={40} />
          <Skeleton variant="rect" width={300} height={40} />
          <Skeleton variant="rect" width={150} height={40} />
          <Skeleton variant="rect" width={150} height={40} />
          <Skeleton variant="rect" width={150} height={40} />
          <Skeleton variant="rect" width={150} height={40} />
        </Box>
      ))}
    </Box>
  );
};

export default SkeletonCharges;

/* eslint-disable no-unused-vars */

import { isValid } from 'date-fns';
import { iso31662 } from 'iso-3166';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import useAlert from 'utils/alert';
import {
  AGENCY_SELECTED_ID,
  CURRENT_PATH,
  DEFAULT_ROUTE
} from 'utils/constants';
import { capitalize } from 'utils/helpers';

import {
  formInfoOrder,
  getPDFInfoPerPage,
  listValidKinds,
  parsedInfoPDF,
  processAmounts,
  processPartiesPromises,
  processPDFRequest,
  resultPArseBlob,
  splitParties,
  validPropertyType
} from './services';
import { createOrderAI } from './services/mutations';

const useStepperHooks = (pdfFBlob: Blob[], onClose:()=>void) => {
  const agencySelected = localStorage.getItem(AGENCY_SELECTED_ID);
  const showAlert = useAlert();
  const history = useHistory();
  const { t } = useTranslation();
  const [createAIHelper, createOrderAIResponse] = useMutation(createOrderAI, {
    onSuccess: (data) => {
      onClose();
      localStorage.setItem(CURRENT_PATH, JSON.stringify({ view: DEFAULT_ROUTE, param: '' }));
      history.push(`/orders/details/${data.helpOrderByAI._id}/${DEFAULT_ROUTE}`);
      showAlert(capitalize(t('dialogs:order-created')), 'success');
    },
    onError: () => {
      createOrderAIResponse.reset();
      showAlert(capitalize('There is an error'), 'error');
    }
  });
  const [shouldFetch, setShouldFetch] = useState(true);
  const methods = useForm<formInfoOrder>({
    defaultValues: {
      address: '',
      city: '',
      state: '',
      county: '',
      zip_code: '',
      country: '',
      purchase_price: 0,
      loan_amount: 0,
      order_type: '',
      property_type: '',
      earnest_money: 0,
      option_fee: 0,
      additional_earnest_money: 0,
      closing_date: '',
      selling_commission: 0,
	    listing_commission: 0,
      acceptance_date: '',
      workflow: 'Purchase Standard',
      transaction_type: 'Purchase',
      parties: [],
      loadingForm: false,
      loadingParties: false,
      errorForm: null,
      errorParties: null,
      loan_number: '',
      references: ''
    }
  });
  const states = iso31662.filter((state) => state.parent === 'US').map((state) => ({
    ...state,
    code: state.code.replace('US-', '')
  }));

  useEffect(() => {
    const getImagesResponse = async (pdf: resultPArseBlob[]) => {
      const { reset, setValue } = methods;
      setValue('loadingForm', true);
      try {
        const responses = await Promise.all(pdf.map((p) => processPDFRequest(p)));
        const parsedResponse:parsedInfoPDF = responses.map((res) => {
          const { response } = res as any;
          try {
            const obj = JSON.parse(response.replace(/[\r\n]+/gm, ' '));
            return { ...obj };
		  } catch (_e:any) {
            return {};
		  }
        }).reduce((prev, curr) => ({ ...prev, ...curr }), {});
        const closingDate = new Date(parsedResponse.closing_date);
        const acceptanceDate = new Date(parsedResponse.acceptance_date);
        const orderType = parsedResponse.loan_amount !== 0 ? 'Closing Disclosure' : 'HUD 1';
        reset({
		  address: parsedResponse.address,
		  city: parsedResponse.city,
		  state: states.find((state) => state.code === parsedResponse.state) ? parsedResponse.state : '',
		  county: parsedResponse.county,
		  zip_code: parsedResponse.zip_code,
		  country: parsedResponse.country,
		  purchase_price: parsedResponse.purchase_price,
		  loan_amount: parsedResponse.loan_amount,
		  order_type: orderType,
		  property_type: validPropertyType.includes(parsedResponse.property_type) ? parsedResponse.property_type : '',
		  earnest_money: parsedResponse.earnest_money,
		  option_fee: parsedResponse.option_fee,
		  additional_earnest_money: parsedResponse.additional_earnest_money,
		  closing_date: isValid(closingDate) ? closingDate : null,
		  acceptance_date: isValid(acceptanceDate) ? acceptanceDate : null,
		  disbursemente_date: isValid(closingDate) ? closingDate : null,
		  funding_date: isValid(closingDate) ? closingDate : null,
		  selling_commission: parseInt(parsedResponse.selling_commision?.replace('%', '').replace('(', '').replace(')', ''), 10),
		  listing_commission: parseInt(parsedResponse.selling_commision?.replace('%', '').replace('(', '').replace(')', ''), 10),
		  workflow: 'Purchase Standard',
		  transaction_type: 'Purchase',
		  loadingForm: false,
		  errorForm: null,
		  loadingParties: true,
		  references: parsedResponse.property_references
        });
        setShouldFetch(false);
        const formattedParties = splitParties(parsedResponse);
        const partiesAsyncRes = await Promise.all(processPartiesPromises(formattedParties));
        setValue('loadingParties', false);
        const partiesWithResults = formattedParties.map((party) => {
          const foundPartyResult = partiesAsyncRes.find((result) => result.param === party.text);
          return {
            ...party,
            parties: foundPartyResult?.searchParty || []
          };
        });
        setValue('parties', partiesWithResults);
      } catch (err) {
        setValue('errorForm', 'there was an error, please try again');
        setShouldFetch(false);
      }
    };
    if (pdfFBlob.length > 0 && shouldFetch) {
      getImagesResponse(getPDFInfoPerPage(pdfFBlob, [1, 2, 3, 5, 9, 10]));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfFBlob, shouldFetch]);

  const handleCreateOrderAI = useCallback((data:formInfoOrder) => {
    createAIHelper({
      transactionType: data.transaction_type,
      type: data.order_type,
      workflow: data.workflow,
      loanNumber: data.loan_number,
      agencyId: agencySelected,
      involvedParties: data.parties.map((party) => ({
        _id: party.partySelected,
        kinds: [listValidKinds[party.role].code]
      })),
	 property: {
        kind: data.property_type,
        country: data.country,
        state: data.state,
        postal_code: data.zip_code,
        street_address: data.address,
        settlement: data.county,
        locality: data.city,
        references: data.references
	 },
	 dates: {
        disbursement_date: data.disbursemente_date,
        acceptance_date: data.acceptance_date,
        closing_date: data.closing_date
	 },
      amounts: processAmounts(data)
    });
  }, [agencySelected, createAIHelper]);

  return {
    methods,
    states,
    handleCreateOrderAI,
    createOrderAIResponse,
    setShouldFetch
  };
};

export default useStepperHooks;

import { useEffect, useState } from 'react';
import { useYupValidationResolver } from 'utils/ledger/use-yup-resolver';
import { validationSchema } from '../services/validate-entry';
import { useMutation } from 'react-query';
import { deleteCharges, updateCharges } from '../services/mutations';
import useAlert from 'utils/alert';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { formatAccountingData } from '../services';

type anchorElRowType = {
  anchorEl: string;
  accountingId: string;
};

const useTableCharges = (accountingData) => {
  const [formatedAccountingData, setFormatedAccountingData] = useState(
    formatAccountingData(accountingData) || []
  );
  const [openModal, setOpenModal] = useState(false);
  const resolver = useYupValidationResolver(validationSchema);
  const showAlert = useAlert();
  const { t } = useTranslation();
  const { id: orderId } = useParams<{ id: string }>();
  const [anchorElRow, setAnchorElRow] = useState<anchorElRowType | null>(null);
  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleClickMenu = (event, accountingId) => {
    setAnchorElRow({
      anchorEl: event.currentTarget,
      accountingId,
    });
  };

  const handleCloseMenu = () => {
    setAnchorElRow(null);
  };

  useEffect(() => {
    setFormatedAccountingData(
      formatAccountingData(accountingData).sort(
        (chargeA, chargeB) => chargeA.number - chargeB.number
      ) || []
    );
  }, [accountingData]);

  const openMenu = Boolean(anchorElRow);

  const [setUpdateCharges, updateChargesResponse] = useMutation(updateCharges, {
    onSuccess: (data) => {
      if (data) {
        showAlert(t('charges:success-updated'), 'success');
      }
    },
    onError: () => {
      showAlert(t('charges:error-updated'), 'error');
    },
  });

  const [setDeleteCharges, deleteChargesResponse] = useMutation(deleteCharges, {
    onSuccess: (data) => {
      if (data) {
        showAlert(t('charges:success-deleted'), 'success');
        setFormatedAccountingData(
          formatedAccountingData.filter(
            (charge) => charge.accounting_id !== anchorElRow?.accountingId
          )
        );
      }
    },
    onError: () => {
      showAlert(t('charges:error-deleted'), 'error');
    },
  });

  const handleDeleteCharge = (accountingId) => {
    setOpenDialog(false);
    setAnchorElRow(null);
    setDeleteCharges({
      id: orderId,
      accountingId,
    });
  };

  const hanldeUpdateCharge = (dataCharges) => {
    setUpdateCharges({
      id: orderId,
      data: dataCharges,
    });
  };

  return {
    openModal,
    setOpenModal,
    resolver,
    hanldeUpdateCharge,
    isLoading: updateChargesResponse.isLoading || deleteChargesResponse.isLoading,
    t,
    anchorElRow,
    handleClickMenu,
    handleCloseMenu,
    openMenu,
    openDialog,
    handleCloseDialog,
    handleOpenDialog,
    handleDeleteCharge,
    formatedAccountingData,
  };
};

export default useTableCharges;

/* eslint-disable max-len */
import { useEffect, useReducer, useState } from 'react';
import { HumanMessage } from "@langchain/core/messages";
import { ChatOpenAI } from "@langchain/openai";
import { TOKEN_CLAIMS } from 'utils/constants';
import {
  assembleChains,
  generatePDFRequest,
  intialReducerState,
  processResponse,
  stateData
} from './services';

const useModalChargesDocument = () => {
  const [dataPDF, setDataPDF] = useState<any>(null);
  const [arrayCharges, setArrayCharges] = useState<any>([]);
  const [state, dispatch] = useReducer(stateData, intialReducerState);
  useEffect(() => {
    const inferenceImage = async (base64Images:string[]) => {
      dispatch({ type: 'loading-data' });
      const access_token = await localStorage.getItem(TOKEN_CLAIMS);
      const model = new ChatOpenAI({
        configuration: {
          baseURL: 'https://llm.titleproject.space',
        },
        modelName: 'claude-haiku',
        openAIApiKey: access_token!,
        temperature: 0.1,
        maxTokens: 4096
      });

      try {
        const res2 = await Promise.all(assembleChains(base64Images).map(p => {
          return model.invoke(
            [new HumanMessage({ content: p })]);
        }));
        setArrayCharges(res2.reduce((prev, curr):any => {
          return [
            ...prev,
            processResponse(curr.content as string)
          ];
        }, []).flat().map((i:any) => ({
          number: i.number || 1,
          description: i.d || '',
          letter: i.l || '',
          payee: i.p || '',
          by_others: i.b_o || 0,
          seller_at_closing: i.s_ac,
          seller_before_closing: i.s_bc || 0,
          borrower_at_closing: i.b_ac || 0,
          borrower_before_closing: i.b_bc || 0
        })));
        dispatch({ type: 'fetched' });
      } catch (e) {
        dispatch({ type: 'error', data: 'there was some error fetching the data,please try again' });
      }
    };
    if (dataPDF?.images.length > 0 && state.mode === 'fetch') {
      inferenceImage(dataPDF?.images || []);
    }
  }, [dataPDF, dataPDF?.images, state.mode]);

  return {
    dataPDF,
    setDataPDF,
    arrayCharges,
    state,
    dispatch
  };
};

export default useModalChargesDocument;

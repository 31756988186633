import { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { AccountingType } from 'v2-types/order';

import { getOrder } from './querys';

const useGetCharges = (currentLetter: string) => {
  const [accounting, setAccounting] = useState<AccountingType[]>([]);
  const { id: orderId } = useParams<{ id: string }>();
  const [hideTax, setHideTax] = useState(true);
  const history = useHistory();

  const redirectToPolicyInfo = () => {
    history.push(`/orders/details/${orderId}/policy-info-and-rates`);
  };

  const { isLoading, isFetching } = useQuery(
    ['accounting-order', orderId],
    () =>
      getOrder(orderId, {
        accounting: currentLetter,
      }),
    {
      enabled: !!orderId && !!currentLetter,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data) {
          setAccounting(data.getOrder.accounting.filter((charge) => charge.number !== null));
        }
      },
    }
  );

  const updatedAccounting = (data) => {
    setAccounting(data.filter((charge) => charge.number !== null));
  };

  return {
    accounting,
    isLoading,
    isFetching,
    updatedAccounting,
    hideTax,
    setHideTax,
    redirectToPolicyInfo,
  };
};

export default useGetCharges;
